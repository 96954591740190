import api from '@/service/api';
import Cookies from '@/service/cookie';

export default {
	/**
	 *
	 * @returns {*}
	 */
	getPoliceCrimes() {
		return api.get('/api/police/crimes');
	},

	/**
	 * Reset password E-mail
	 *
	 * Sends an e-mail containing link to
	 * reset the password for the given user.
	 *
	 * @param email
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	sendResetPasswordMail(email) {
		return api.post('/api/forgot-password', { email: email });
	},

	/**
	 * Send a password reset request.
	 *
	 * Contains new password, associated e-mail
	 * and verification token.
	 *
	 * @param email
	 * @param password
	 * @param token
	 *
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	sendResetPasswordRequest(email, password, token) {
		return api.post('/api/reset-password', {
			email: email,
			password: password,
			password_confirmation: password,
			token: token,
		});
	},

	/**
	 * Export to PDF
	 *
	 * Exports current query from ReactiveSearch
	 * in order to generate a PDF based on result set.
	 *
	 * @param type
	 * @param query
	 * @param template
	 * @param format
	 *
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	exportCurrentSearch(type, query, template, format) {
		return api.post('/api/export', { type: type, query: query, template: template, format: format }, { responseType: 'blob' });
	},


	exportMembersFromElastic(query, template) {
		return api.post('/api/export/members', { query: query, template: template });
	},

	exportMembersFromElasticEstimate(query) {
		return api.post('/api/export/members/estimate', { query: query });
	},

	/**
	 * Send Bulk Mail from ES Query.
	 *
	 * @param subject
	 * @param query
	 * @param body
	 *
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	sendBulkMailES(query, subject, body, signature, type, to_email, ticket_id) {
		return api.post('/api/mail/bulk/es', { query: query, subject: subject, body: body, signature_id: signature, type: type, to_email: to_email, ticket_id: ticket_id });
	},

	/**
	 * Save search
	 *
	 * Stores the raw query returned from
	 * ReactiveSearch to API. Used for e-mail
	 * lists.
	 *
	 * @param query
	 * @param label
	 * @param type
	 *
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	saveSearch(query, label, type, global) {
		return api.post('/api/email/lists', { query: query, label: label, type: type, global: global });
	},

	/**
	 * Retrieve a list of all saved searches.
	 *
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	getEmailLists() {
		return api.get('/api/email/lists');
	},

	/**
	 * Retrieve a list of all outgoing mails.
	 *
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	getEmails() {
		return api.get('/api/email/list');
	},

	/**
	 * Retrieve a list of e-mail signatures
	 *
	 * Return all e-mail signatures available to
	 * current used based on their permission level.
	 *
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	getEmailSignatures() {
		return api.get('/api/email/signatures');
	},

	/**
	 * Sends a preview of an mail.
	 *
	 * @param body
	 * @param subject
	 *
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	sendEmailPreview(subject, body, signature_id) {
		return api.post('/api/email/preview', { body: body, subject: subject, signature_id: signature_id });
	},

	/**
	 * Sends an email to one or more lists
	 *
	 * @param subject
	 * @param body
	 * @param signature_id
	 * @param lists
	 * @param sent_at
	 *
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	sendEmailWithLists(formData) {
		return api.post('/api/email/send', formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
	},

	/**
	 * Return estimate on number of recipents
	 *
	 * Used before actually sending an e-mail to give the
	 * user an idea of how many people will receive the mail.
	 *
	 * @param subject
	 * @param body
	 * @param signature_id
	 * @param lists
	 * @param date
	 * @param time
	 *
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	sendEmailWithListsPreview(subject, body, signature_id, lists, date, time) {
		return api.post('/api/email/send/preview', {
			body: body,
			subject: subject,
			lists: lists,
			date: date,
			time: time,
			signature_id: signature_id,
		});
	},

	/**
	 * Delete e-mail
	 *
	 * @param mail_id
	 *
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	deleteEmail(mail_id) {
		return api.delete('/api/email/' + mail_id);
	},

	/**
	 * Retrieve a single local office.
	 *
	 * @param localOffice
	 *
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	getLocalOffice(localOffice) {
		return api.get('/api/localoffice/' + localOffice);
	},

	/**
	 * Retrieve actions for comments for local office.
	 *
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	getLocalOfficeActions() {
		return api.get('/api/localoffice/actions');
	},

	getOfficeSurveyStatistics(office, filters) {
		return api.get('/api/office/' + office + '/statistics/surveys', { params: filters });
	},

	createSurvey(ticket, tag) {
		return api.post('/api/ticket/' + ticket.id + '/survey', {name: tag});
	},

	/**
	 * Save or update a comment on a local office
	 *
	 * @param localoffice_id
	 * @param comment
	 *
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	saveLocalOfficeComment(localoffice_id, comment) {
		return api.post('/api/localoffice/' + localoffice_id + '/comment', comment);
	},

	/**
	 * Delete comment on a local office.
	 *
	 * @param localoffice_id
	 * @param comment
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	deleteLocalOfficeComment(localoffice_id, comment) {
		return api.delete('/api/localoffice/' + localoffice_id + '/comment/' + comment.id);
	},

	/**
	 * Update a single field on a local office model.
	 *
	 * @param local_office
	 * @param field
	 * @param ids
	 *
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	updateLocalOfficeField(local_office, field, ids) {
		return api.post('/api/localoffice/' + local_office.id + '/field', { field, ids });
	},

	/**
	 *
	 * @param local_office
	 * @param field
	 * @param ids
	 *
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	updateLocalOfficeInformationField(local_office, field, ids) {
		return api.post('/api/localoffice/' + local_office.id + '/information/field', field);
	},

	/**
	 * Update a single field on an office model.
	 *
	 * @param office
	 * @param field
	 * @param ids
	 *
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	updateOfficeField(office, field, ids) {
		return api.post('/api/office/' + office.id + '/field', { field, ids });
	},

	/**
	 * Get options for local office.
	 *
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	getLocalOfficeOptions() {
		return api.get('/api/localoffice/options');
	},

	/**
	 * Get InfoBoxes
	 *
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	getInfoBoxes() {
		return api.get('/api/info/boxes');
	},

	/**
	 * Retrieve current authenticated user
	 *
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	getUserInformation() {
		return api.get('/api/user');
	},

	/**
	 * Retrieve information about a user by id
	 *
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	getUserById($user_id) {
		return api.get('/api/user/' + $user_id);
	},

	/**
	 * Retrieve all tickets for current user
	 *
	 * @returns {*}
	 */
	getUserTickets() {
		return api.get('/api/tickets');
	},

	/**
	 *
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	getUsersAndTicketRoles() {
		return api.get('/api/tickets/roles-users');
	},

	identificationsSecurifid(ticketId, memberId, sendToMobile = false, useOrganizationSmsTextOverride = false) {
		return api.post('/api/securifid/identifications', {
			ticket_id: ticketId,
			member_id: memberId,
			send_to_mobile: sendToMobile,
			useOrganizationSmsTextOverride: useOrganizationSmsTextOverride,
		});
	},

	userSecurifid(ticketId, memberId) {
		return api.get('/api/securifid/users?ticket_id=' + ticketId + '&member_id=' + memberId);
	},
	/**
	 * Add or remove user assigned to a ticket.
	 *
	 * @param ticket
	 * @param payload
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	saveTicketUser(ticket, payload) {
		return api.post('/api/tickets/' + ticket + '/roles-users/update', payload);
	},

	/**
	 * Add or remove a consent field on a ticket.
	 *
	 * @param ticket
	 * @param payload
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	updateTicketConsentField(ticket, payload) {
		return api.post('/api/ticket/' + ticket.id + '/consent/field', payload);
	},

	updateTicketConsentEmail(ticket, payload) {
		return api.post('/api/ticket/' + ticket.id + '/consent/email', payload);
	},

	updateTicketConsentSms(ticket, payload) {
		return api.post('/api/ticket/' + ticket.id + '/consent/sms', payload);
	},

	/**
	 * Retrieve a single ticket by ID
	 *
	 * @param ticket
	 * @returns {*}
	 */
	getTicket(ticket, payload) {
		return api.get('/api/tickets/' + ticket, payload);
	},

	getExternalEmail(ticket) {
		return api.get('/api/external-email/' + ticket);
	},

	/**
	 *
	 * @param ticket
	 * @returns {*}
	 */
	saveTicket(ticket) {
		return api.post('/api/tickets/new', ticket);
	},

	anonymizeTicket(ticketId) {
		return api.post('/api/ticket/' + ticketId + '/anonymize');
	},

	newTicketFromTicket(ticket) {
		return api.get('/api/tickets/' + ticket.id + '/create-from-ticket');
	},

	/**
	 * Update ticket
	 *
	 * @param ticket
	 * @returns {*}
	 */
	updateTicket(ticket) {
		return api.post('/api/ticket/' + ticket.id + '/update', ticket);
	},

	deleteTicket(ticketId) {
		return api.delete('/api/tickets/' + ticketId);
	},

	/**
	 * Email a ticket
	 *
	 * @param ticket
	 * @returns {*}
	 */
	sendTicket(ticket) {
		return api.get('/api/ticket/' + ticket.id + '/mail');
	},

	sendTicketNotification(ticket) {
		return api.get('/api/ticket/' + ticket.id + '/mail/notification');
	},

	sendTicketSpesPing(ticket) {
		return api.get('/api/ticket/' + ticket.id + '/mail/spes-ping');
	},

	sendTicketOfficePing(ticket) {
		return api.get('/api/ticket/' + ticket.id + '/mail/office-ping');
	},

	sendTicketUpdateNotification(ticket) {
		return api.get('/api/ticket/' + ticket.id + '/mail/update-notification');
	},

	updateCrimeRelationTicketField(crime_relation_ticket, field, ids) {
		return api.post('/api/crime-relation-tickets/' + crime_relation_ticket.id, { field, ids });
	},

	/**
	 * Update a relationship field on the ticket
	 *
	 * @param ticket
	 * @param field
	 * @param ids
	 * @returns {*}
	 */
	updateTicketField(ticket, field, ids) {
		return api.post('/api/ticket/' + ticket.id + '/field', { field, ids });
	},

	addSecondaryCrime(ticket_id) {
		return api.post('/api/ticket/' + ticket_id + '/add-secondary-crime');
	},

	updateCrimeField(crime, field, ids) {
		return api.post('/api/crime/' + crime.id + '/field', { field, ids });
	},

	deleteCrime(crime_id) {
		return api.delete('/api/crime/' + crime_id + '/destroy');
	},

	/**
	 * Submit request for removal of a ticket.
	 *
	 * @param ticket
	 * @returns {*}
	 */
	sendRemovalRequest(ticket) {
		return api.get('/api/ticket/' + ticket.id + '/removal');
	},

	/**
	 * Update a field on a person assigned to a ticket.
	 *
	 * @param ticket
	 * @param field
	 * @param ids
	 * @returns {*}
	 */
	updateTicketPersonField(ticket, field, ids) {
		return api.post('/api/ticket/' + ticket.id + '/person/field', { field, ids });
	},

	/**
	 * Save new comment on a ticket.
	 *
	 * @param ticket
	 * @param comment
	 * @returns {*}
	 */
	saveNewTicketComment(ticket, comment) {
		return api.post('/api/ticket/' + ticket.id + '/comment', comment);
	},

	removeTicketComment(ticket, comment) {
		return api.delete('/api/ticket/' + ticket.id + '/comment/' + comment.id);
	},

	/**
	 * Todos
	 */
	saveNewTodo(ticket, todo, userId) {
		return api.post('/api/todos', {
			todo_category_id: todo.todo_category.id,
			ticket_id: ticket.id,
			user_id: userId,
			comment: todo.comment,
			due_at: todo.due_at_datetime_local,
		});
	},

	updateTodo(ticket, todo, userId) {
		return api.post('/api/todos/' + todo.id, {
			todo_category_id: todo.todo_category.id,
			ticket_id: todo.ticket_id,
			user_id: userId,
			comment: todo.comment,
			due_at: todo.due_at_datetime_local,
		});
	},

	removeTodo(todo) {
		return api.delete('/api/todos/' + todo.id);
	},

	getTodoCategories() {
		return api.get('/api/todo-categories');
	},

	/**
	 *
	 * @returns {*}
	 */
	getActions() {
		return api.get('/api/actions');
	},

	/**
	 *
	 * @returns {*}
	 */
	getStatuses() {
		return api.get('/api/fields/statuses');
	},

	getPostal() {
		return api.get('/api/fields/postal');
	},

	getDistricts() {
		return api.get('/api/fields/districts');
	},

	getExposedAreas() {
		return api.get('/api/fields/exposed-areas');
	},

	/**
	 *
	 * @returns {*}
	 */
	getPriority() {
		return api.get('/api/fields/priority');
	},

	/**
	 * Retrieve options for tickets
	 * @returns {*}
	 */
	getTicketOptions() {
		return api.get('/api/tickets/options');
	},

	exportTicketsFromElastic(query) {
		return api.post('/api/export/tickets', { query: query });
	},

	exportTicketsFromElasticEstimate(query) {
		return api.post('/api/export/tickets/estimate', { query: query });
	},

	exportCommentsFromElastic(query) {
		return api.post('/api/export/comments', { query: query });
	},

	exportCommentsFromElasticEstimate(query) {
		return api.post('/api/export/comments/estimate', { query: query });
	},

	exportPeopleFromElastic(query) {
		return api.post('/witness-api/export/people', { query: query });
	},

	exportPeopleFromElasticEstimate(query) {
		return api.post('/witness-api/export/people/estimate', { query: query });
	},

	exportTestimonyTimeFromElastic(query) {
		return api.post('/witness-api/export/testimony-time', { query: query });
	},

	exportTestimonyTimeFromElasticEstimate(query) {
		return api.post('/witness-api/export/testimony-time/estimate', { query: query });
	},

	/**
	 * Calendar/Bookings
	 */

	getBookingTemplates() {
		return api.get('/api/bookings/templates');
	},

	updateBookingTemplate(payload) {
		return api.post('/api/bookings/templates', payload);
	},

	deleteBookingTemplate(bookingTemplateId) {
		return api.delete('/api/bookings/templates/' + bookingTemplateId);
	},

	getBookingTypes() {
		return api.get('/api/bookings/types');
	},

	getBookingCategories() {
		return api.get('/api/bookings/categories');
	},

    getBookables() {
        return api.get('/api/bookings/bookables');
    },

	getBookingFilters() {
        return api.get('/api/bookings/filters');
    },

	getBooking(id) {
		return api.get('/api/bookings/' + id);
	},
	getAvailableBookings(filters) {
		return api.get('/api/bookings/available', { params: filters });
    },
	groupAvailableBookings(filters) {
		return api.get('/api/bookings/group-by-date', { params: filters });
	},
    participateInBooking(bookingId) {
        return api.post('/api/bookings/' + bookingId + '/participate');
    },
    cancelBooking(bookingId, userId) {
        return api.post('/api/bookings/' + bookingId + '/cancel', { user_id: userId });
    },
	deleteBooking(bookingId) {
		return api.delete('/api/bookings/' + bookingId);
	},
    createBooking(payload) {
        return api.post('/api/bookings', payload);
    },
	updateBookingTime(bookingId, payload) {
		return api.post('/api/bookings/' + bookingId + '/time', payload);
	},

	getBookings() {
		return api.get('/api/bookings');
	},

	/**
	 *  Invites for Calendar/Bookings
	 */
	getInvitedUser() {
		return api.get('/api/invites/bookings/me', {
			headers: {
				'uuid': Cookies.get('invite-token')
			}
		});
	},

	participateBookingInvite(bookingId) {
		return api.post('/api/invites/bookings/' + bookingId + '/participate', {}, {
			headers: {
				'uuid': Cookies.get('invite-token')
			}
		});
	},
	cancelBookingInvite(bookingId) {
		return api.post('/api/invites/bookings/' + bookingId + '/cancel', {}, {
			headers: {
				'uuid': Cookies.get('invite-token')
			}
		});
	},

	getInvitedBooking(id) {
		return api.get('/api/invites/bookings/' + id, { 
			headers: {
				'uuid': Cookies.get('invite-token')
			} 
		});
	},

	getInvitedGroupAvailableBookings(filters) {
		return api.get('/api/invites/bookings/group-by-date', { 
			params: filters, 
			headers: {
				'uuid': Cookies.get('invite-token')
			} 
		});
	},

	getInvitedAvailableBookings(filters) {
		return api.get('/api/invites/bookings/available', { 
			params: filters,
			headers: {
				'uuid': Cookies.get('invite-token')
			} 
		});
	},

	countTotalBookingsAvailable(filters) {
		return api.get('/api/invites/bookings/available/total', {
			params: filters,
			headers: {
				'uuid': Cookies.get('invite-token')
			}
		});
	},

	sendCalendarInvite(id, type, refId) {
		return api.post('/api/invites/bookings', {
			'id': id,
			'type': type,
			'ref_id': refId,
		});
	},

	/**
	 * Retrieve a single member by ID
	 *
	 * @param member
	 * @returns {*}
	 */
	getMember(member) {
		return api.get('/api/member/' + member);
	},

	/**
	 *
	 * @param member
	 * @param field
	 * @param ids
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	updateMemberInformationField(member, field, ids) {
		return api.post('/api/member/' + member.id + '/information/field', field);
	},

	/**
	 *
	 * @param member
	 * @param payload
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	memberAssignUser(member, payload) {
		return api.post('/api/member/' + member.id + '/assign-user', payload);
	},

	/**
	 * Delete member
	 *
	 * Performs a soft delete on a given member.
	 *
	 * @param member
	 * @returns {*}
	 */
	deleteMember(member) {
		return api.delete('/api/member/' + member);
	},

	/**
	 * Retrieve all available options for members
	 *
	 * @returns {*}
	 */
	getMemberOptions() {
		return api.get('/api/member/options');
	},

	/**
	 * Creates a new member
	 *
	 * @returns {*}
	 */
	createNewMember(local_office_id) {
		return api.post('/api/member/new-member', { id: local_office_id });
	},

	/**
	 * Update a single field on a member
	 *
	 * @param member
	 * @param field
	 * @param ids
	 * @returns {*}
	 */
	updateMemberField(member, field, ids) {
		return api.post('/api/member/' + member.id + '/field', { field, ids });
	},

	/**
	 * Retrieve all members
	 * @returns {*}
	 */
	getAllMembers() {
		return api.get('/api/member');
	},

	/**
	 * Retrieve a list of all users.
	 *
	 * @returns {*}
	 */
	getUsers() {
		return api.get('/api/users');
	},

	/**
	 * Retrieve manual.
	 *
	 * @returns {*}
	 */
	getManuals(place) {
		return api.get('/api/manual/' + place);
	},

	/**
	 * Retrieve manual.
	 *
	 * @returns {*}
	 */
	getManualName(id) {
		return api.get('/api/manual/get-name/' + id);
	},

	/**
	 * Retrieve manual blob.
	 *
	 * @returns {*}
	 */
	getManualFile(id) {
		return api.get('/api/manual/get-file/' + id, {
			responseType: 'blob'
		});
	},

	getPrintServiceFile() {
		return api.get('/api/files/print-service', {
			responseType: 'blob'
		});
	},

	/**
	 *
	 * WITNESS API
	 *
	 * ----------------------------------------------------------------
	 */

	/**
	 * Retrieve a single witness by ID
	 *
	 * @param witness
	 * @returns {*}
	 */
	getTestimony(testimonyId, payload) {
		return api.get('/witness-api/testimony/' + testimonyId, payload);
	},

	deleteTestimony(testimonyId) {
		return api.delete('/witness-api/testimony/' + testimonyId);
	},

	/**
	 * Retrieve options for tickets
	 * @returns {*}
	 */
	getTestimonyOptions() {
		return api.get('/witness-api/options');
	},

	/**
	 * Update a relationship field on the testimony
	 *
	 * @param testimony
	 * @param field
	 * @param ids
	 * @returns {*}
	 */
	updateTestimonyField(testimony, field, ids) {
		return api.post('/witness-api/testimony/' + testimony.id + '/field', { field, ids });
	},

	/**
	 * Update a relationship field on the testimony
	 *
	 * @param person
	 * @param field
	 * @param ids
	 * @returns {*}
	 */
	updateTestimonyPersonField(person, field, ids) {
		return api.post('/witness-api/testimony/persons/' + person.id + '/field', { field, ids });
	},

	/**
	 *
	 * @param {id} testimony_id
	 * @returns
	 */
	addTestimonyPerson(testimony_id) {
		return api.post('/witness-api/testimony/' + testimony_id + '/add-person');
	},

	/**
	 *
	 * @param {id} person_id
	 * @returns
	 */
	removeTestimonyPerson(person_id) {
		return api.delete('/witness-api/testimony/persons/' + person_id + '/remove-person');
	},

	/**
	 *
	 * @param testimony
	 * @returns {*}
	 */
	createTestimony(testimony) {
		return api.post('/witness-api/testimony/create', testimony);
	},

	/**
	 *
	 * @param testimony
	 * @returns {*}
	 */
	createTestimonyTime(testimony) {
		return api.post('/witness-api/testimony/time/create-and-register-time', testimony);
	},

	/**
	 *
	 * @param testimony
	 * @returns {*}
	 */
	getTestimonyTime(testimonyTimeId) {
		return api.get('/witness-api/testimony/time/' + testimonyTimeId);
	},

	/**
	 *
	 * @param {id} time
	 * @returns
	 */
	updateTestimonyTime(time, data) {
		return api.post('/witness-api/testimony/time/' + time.id + '/field', data);
	},

	/**
	 *
	 * @param {id} time
	 * @returns
	 */
	removeSingleTestimonyTime(time, timeType) {
		return api.delete('/witness-api/testimony/time/' + time + '/type/' + timeType + '/remove-single-time');
	},

	/**
	 *
	 * @param {id} time
	 * @returns
	 */
	removeTestimonyTime(time, timeType) {
		return api.delete('/witness-api/testimony/time/' + time + '/remove-time');
	},

	/**
	 *
	 * @returns {*}
	 */
	getTestimonialTimeTypes() {
		return api.get('/witness-api/fields/timetypes');
	},

	exportTestimonyFromElastic(query) {
		return api.post('/witness-api/export/testimony', { query: query });
	},

	exportTestimonyFromElasticEstimate(query) {
		return api.post('/witness-api/export/testimony/estimate', { query: query });
	},


	/* AD tickets */

	exportCombinedTicketsFromElastic(query) {
		return api.post('/api/export/combined-tickets', { query: query });
	},

	exportCombinedTicketsFromElasticEstimate(query) {
		return api.post('/api/export/combined-tickets/estimate', { query: query });
	},


	getPoliceTickets(params) {
		return api.get('/api/police-import-tickets', params);
	},

	getPoliceImportTicket(id) {
		return api.get('/api/police-import-tickets/'+id);
	},
	
	deletePoliceImportTicket(ids) {
		return api.post('/api/police-import-tickets/destroy', { ids: ids });
	},

	updatePoliceImportTicketField(ticket, field, ids) {
		return api.post('/api/police-import-tickets/' + ticket.id + '/field', { field, ids });
	},

	updatePoliceImportPersonField(person, field, ids) {
		return api.post('/api/police-import-tickets/' + person.id + '/person/field', { field, ids });
	},

	updatePoliceImportCrimeField(policeImportCrime, field, ids) {
		return api.post('/api/police-import-crime/' + policeImportCrime.id + '/field', { field, ids });
	},


	createTicketsFromPoliceTickets(ids) {
		return api.post('/api/police-import-tickets/import', {ids: ids});
	},

	deletePoliceImportError(error) {
		return api.delete('/api/police-import-errors/' + error.id);
	},


	/**
	 * getPoliceFiles
	 *
	 * @returns {*}
	 */
	getPoliceFiles(params) {
		return api.get('/api/police-import', params, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});
	},

	/**
	 * UploadPoliceFile
	 *
	 * @returns {*}
	 */

	uploadPoliceFile(formData) {
		return api.post('/api/police-import/upload', formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			},
		});
	},

	/**
	 * deletePoliceFile
	 *
	 * @returns {*}
	 */

	deletePoliceFile(id) {
		return api.delete('/api/police-import/delete/' + id);
	},

	/**
	 * importPoliceFile
	 *
	 * @returns {*}
	 */

	importPoliceFile(id) {
		return api.post('/api/police-import/import/' + id);
	},



	/**
	 * Attempt login
	 *
	 * @param username
	 * @param password
	 * @returns {*}
	 */
	login(username, password) {
		return api.post('/api/login', {
			email: username,
			password: password,
		});
	},

	newOtp() {
		return api.post('/api/otp');
	},

	getOtpOptions() {
		return api.get('/api/otp/options');
	},

	verifyOtp(token) {
		return api.post('/api/otp/verify', {
			token: token,
		});
	},

	updateOtpEmail(email) {
		return api.post('/api/otp/email', {
			otp_email: email
		});
	},

	updateOtpSms(sms) {
		return api.post('/api/otp/sms', {
			otp_sms: sms
		});
	},

	updateOtpChannel(channel) {
		return api.post('/api/otp/channel', {
			otp_channel: channel
		});
	},

	/**
	 * Attempt logout
	 *
	 * @param username
	 * @param password
	 * @returns {*}
	 */
	logout(username, password) {
		return api.post('/api/logout', {
			email: username,
			password: password,
		});
	},

	/**
	 * Attempt get refresh token
	 *
	 * @param username
	 * @param password
	 * @returns {*}
	 */
	refreshAccessToken(username, password) {
		return api.post('/api/refresh-access-token', {
			email: username,
			password: password,
		});
	},
};
