<template>
	<layout-main>
		<select-local-office v-if="selectLocalOffice" @closed="closeSelectOffice"></select-local-office>
		<div class="survey">
			<!-- Header -->
			<div class="subheader">
				<div class="subheader-main">
					<div class="subheader-left">
						<h3>{{ $t('labels.import-ticket') }} #{{ policeImportTicket.id }}</h3>
						<span class="separator-line"></span>
						<div class="breadcrumbs" v-if="!isLoading">
							<router-link :to="{ name: 'police-import' }" class="breadcrumbs-link"> {{ $t('labels.home') }}</router-link>
							<span span class="separator-dot"></span>
							<router-link :to="{ name: 'police-import' }" class="breadcrumbs-link"> {{ $t('labels.import') }}</router-link>
						</div>
					</div>
					<div class="subheader-right"></div>

					<button
						class="btn btn-wide font-bold btn-primary mr-3"
						@click="toggleRejected()"
						:disabled="!!policeImportTicket.is_imported"
						:class="!policeImportTicket.is_imported ? 'btn-primary' : 'btn-secondary'"
					>
						<span v-if="!policeImportTicket.is_rejected">{{ $t('labels.reject') }}</span
						><span v-else>{{ $t('labels.rejected') }}</span>
					</button>

					<button
						class="btn btn-wide font-bold mr-3"
						@click="createTicket()"
						:class="!!policeImportTicket.is_rejected || !!policeImportTicket.is_imported || !!errorMessages.length > 0 ? 'btn-secondary' : 'btn-success'"
						:disabled="!!policeImportTicket.is_rejected || !!policeImportTicket.is_imported || !!errorMessages.length > 0"
					>
						<span v-if="!policeImportTicket.is_imported">{{ $t('labels.do-import') }}</span
						><span v-else>{{ $t('labels.imported') }}</span>
					</button>
					<button class="btn" :class="!!policeImportTicket.is_rejected || !!policeImportTicket.is_imported ? 'btn-secondary' : 'btn-danger'" :disabled="!!policeImportTicket.is_rejected || !!policeImportTicket.is_imported" @click="deletePoliceImportTicket()">{{ $t('labels.delete') }}</button>
				</div>
			</div>

			<div class="widget overview" v-if="!isLoading">
				<div class="widget-body pr-0 pl-3">
					<div class="container-fluid">
						<div class="row">
							<div class="col-3">
								<div class="list overview-middle">
									<div class="list-item">
										<div style="font-size: 1.6rem" class="fw-500">
											{{ policeImportTicket.first_name }} {{ policeImportTicket.last_name }}

											<div v-if="policeImportTicket.duplicates.length > 0">
												<label><i class="fas fa-exclamation-circle text-warning"></i> {{ $t('labels.possible-duplicates') }}:</label>

												<span v-for="ticket in policeImportTicket.duplicates" :key="ticket">
													<router-link target="_blank" :to="{ name: 'ticket', params: { id: ticket } }" class="notification-title">
														# {{ ticket }}
													</router-link>
												</span>
											</div>
										</div>
									</div>
									<div class="list-item">
										<label>{{ $t('labels.victim-support-centre') }}</label>
										<span v-if="policeImportTicket.local_office && policeImportTicket.local_office.office">{{
											policeImportTicket.local_office.office.label
										}}</span>
									</div>
									<div class="list-item">
										<label>{{ $t('labels.local-office') }}</label>
										<span v-if="policeImportTicket.local_office">{{ policeImportTicket.local_office.label }}</span>
									</div>
								</div>
							</div>
							<div class="col-3">
								<div class="list overview-middle">
									<div
										class="list-item"
										v-if="policeImportTicket.show_errors && errorMessages.length > 0"
									>
										<i class="fas fa-exclamation-circle text-warning"></i>

										{{ errorMessages }}
									</div>
									<div class="list-item">
										<label>ÄrendeID</label>
										<span v-if="policeImportTicket.ticket_id"
											><router-link
												target="_blank"
												:to="{ name: 'ticket', params: { id: policeImportTicket.ticket_id } }"
												class="breadcrumbs-link"
												># {{ policeImportTicket.ticket_id }}</router-link
											></span
										>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="widget-footer with-tabs">
					<ul class="nav nav-tabs nav-tabs-line" role="tablist">
						<li class="nav-item section" :class="{ 'current-section': showDetailSection === 1 }">
							<div class="nav-link" @click="setShowDetailSection(1)">{{ $t('labels.about-applicant') }}</div>
						</li>
						<li class="nav-item section" :class="{ 'current-section': showDetailSection === 2 }">
							<div class="nav-link" @click="setShowDetailSection(2)">{{ $t('labels.about-ticket') }}</div>
						</li>
						<li class="nav-item section" :class="{ 'current-section': showDetailSection === 3 }">
							<div class="nav-link" @click="setShowDetailSection(3)">{{ $t('labels.geography') }}</div>
						</li>
						<li class="nav-item section" :class="{ 'current-section': showDetailSection === 4 }">
							<div class="nav-link" @click="setShowDetailSection(4)">{{ $t('labels.primary-crime-type-boj') }}</div>
						</li>
						<li class="nav-item section" :class="{ 'current-section': showDetailSection === 5 }">
							<div class="nav-link" @click="setShowDetailSection(5)">{{ $t('labels.additional-crimes') }}</div>
						</li>
						<li class="nav-item section" :class="{ 'current-section': showDetailSection === 6 }">
							<div class="nav-link" @click="setShowDetailSection(6)">{{ $t('labels.police-handling') }}</div>
						</li>
					</ul>
				</div>
				<div class="widget-body bt-1 pr-0 pl-3" v-if="showDetailSection === 1">
					<div class="container-fluid">
						<div class="row">
							<div class="col-4 br-1">
								<h5 class="bb-1 pb-3 mb-3" style="font-size: 1rem">{{ $t('labels.name-gender-age') }}</h5>

								<text-field
									:model="policeImportTicket"
									api="updatePoliceImportTicketField"
									field="first_name_raw"
									:label="this.$t('labels.first-name')"
									@fieldSaved="getPoliceImportTicket()"
									:editable="isEditable"
								></text-field>
								<text-field
									:model="policeImportTicket"
									api="updatePoliceImportTicketField"
									field="first_names_raw"
									:label="this.$t('labels.all-first-names')"
									:editable="isEditable"
								></text-field>
								<text-field
									:model="policeImportTicket"
									api="updatePoliceImportTicketField"
									field="last_name_raw"
									:label="this.$t('labels.last-name')"
									@fieldSaved="getPoliceImportTicket()"
									:editable="isEditable"
								></text-field>

								<select-field
									api="updatePoliceImportTicketField"
									:clearable="false"
									:model="policeImportTicket"
									:multiple="false"
									:options="ticketFieldOptions.gender"
									db_field="gender_id"
									field="gender"
									:label="this.$t('labels.gender')"
									:optionLabel="this.$label()"
									:editable="isEditable"
								>
									<template v-slot:icon>
										<i v-b-toggle:gender_id
											><span class="fas fa-minus when-open"></span><span class="fas fa-plus when-closed"></span
										></i> </template
								></select-field>
								<b-collapse id="gender_id" class="form-group"><label>Polisens fil: </label> <mark>{{ policeImportTicket.gender_raw }}</mark></b-collapse>

								<text-field
									:model="policeImportTicket"
									api="updatePoliceImportTicketField"
									field="birth_year_raw"
									:label="this.$t('labels.birth-year')"
									:editable="isEditable"
									@fieldSaved="getPoliceImportTicket()"
								></text-field>

								<select-field
									api="updatePoliceImportTicketField"
									:model="policeImportTicket"
									:options="ticketFieldOptions.language"
									db_field="language_id"
									field="language"
									:label="this.$t('labels.language')"
									:optionLabel="this.$label()"
									:editable="isEditable"
									@fieldSaved="getPoliceImportTicket()"
								><template v-slot:icon>
										<i v-b-toggle:language_id
											><span class="fas fa-minus when-open"></span><span class="fas fa-plus when-closed"></span
										></i> </template
								>
								</select-field>
								<b-collapse id="language_id" class="form-group"><label>Polisens fil: </label> <mark>{{ policeImportTicket.language_raw }}</mark></b-collapse>


							</div>
							<div class="col-4 br-1">
								<h5 class="bb-1 pb-3 mb-3" style="font-size: 1rem">{{ $t('labels.contact-information') }}</h5>

								<text-field
									:model="policeImportTicket"
									api="updatePoliceImportTicketField"
									field="email_raw"
									:label="this.$t('labels.email-one')"
									:editable="isEditable"
								></text-field>

								<text-field
									:model="policeImportTicket"
									api="updatePoliceImportTicketField"
									field="mobile_raw"
									:label="this.$t('labels.mobile')"
									@fieldSaved="getPoliceImportTicket()"
									:editable="isEditable"
								></text-field>
							</div>

							<div class="col-4 br-1">
								<h5 class="bb-1 pb-3 mb-3" style="font-size: 1rem">{{ $t('labels.address') }}</h5>

								<text-field
									:model="policeImportTicket"
									api="updatePoliceImportTicketField"
									field="co_address_raw"
									:label="this.$t('labels.possible-co-address')"
									:editable="isEditable"
								></text-field>

								<text-field
									:model="policeImportTicket"
									api="updatePoliceImportTicketField"
									field="streetname_raw"
									:label="this.$t('labels.address')"
									:editable="isEditable"
								></text-field>

							</div>
						</div>
					</div>
				</div>
				<div class="widget-body bt-1-dark pr-0 pl-3" v-if="showDetailSection === 2">
					<div class="container-fluid">
						<div class="row">
							<div class="col-12">
								<h5 class="bb-1 pb-3 mb-3" style="font-size: 1rem">{{ $t('labels.other-about') }}</h5>

								<div class="row">
									<div class="col-6">
										<select-field
											:options="ticketFieldOptions.category"
											db_field="category_id"
											field="category"
											:label="this.$t('labels.category')"
											:optionLabel="this.$label()"
											api="updatePoliceImportTicketField"
											:model="policeImportTicket"
											:editable="false"
										></select-field>

										<select-field
											api="updatePoliceImportTicketField"
											:model="policeImportTicket"
											:options="ticketFieldOptions.police"
											db_field="police_report_id"
											field="police_report"
											:label="this.$t('labels.police-report')"
											:optionLabel="this.$label()"
											:editable="false"
										></select-field>

										<select-field
											api="updatePoliceImportTicketField"
											:model="policeImportTicket"
											:options="ticketFieldOptions.first_contact"
											db_field="first_contact_id"
											field="first_contact"
											:label="this.$t('labels.first-contact')"
											:optionLabel="this.$label()"
											:editable="isEditable"
										></select-field>

										<select-field
											api="updatePoliceImportTicketField"
											:model="policeImportTicket"
											:options="ticketFieldOptions.channel"
											db_field="ticket_channel_id"
											field="ticket_channel"
											:label="this.$t('labels.received-via')"
											:optionLabel="this.$label()"
											:editable="false"
										></select-field>

										<select-field
											api="updatePoliceImportTicketField"
											:model="policeImportTicket"
											:options="ticketFieldOptions.priority"
											db_field="priority_id"
											field="priority"
											:label="this.$t('labels.priority')"
											:optionLabel="this.$label()"
											:editable="false"
										></select-field>
									</div>
									<div class="col-6">
										<select-field
											api="updatePoliceImportTicketField"
											:clearable="false"
											:model="policeImportTicket"
											:multiple="false"
											:options="ticketFieldOptions.crime_relation"
											db_field="crime_relation_id"
											field="crimerelation"
											:label="this.$t('labels.special-circumstances')"
											:optionLabel="this.$label()"
											:editable="isEditable"
										>
											<template v-slot:icon>
												<i v-b-toggle:special_circumstances
													><span class="fas fa-minus when-open"></span><span class="fas fa-plus when-closed"></span
												></i> </template
										></select-field>
										<b-collapse id="special_circumstances" class="form-group"><label>Polisens fil: </label> <mark>{{ policeImportTicket.special_circumstances_raw }}</mark></b-collapse>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="widget-body bt-1 pr-0 pl-3" v-if="showDetailSection === 3">
					<div class="container-fluid">
						<div class="row">
							<div class="col-4 br-1">
								<h5 class="bb-1 pb-3 mb-3" style="font-size: 1rem">{{ $t('labels.boj') }}</h5>

								<div class="form-group row" v-if="policeImportTicket.local_office">
									<label for="ticket_police_county" class="col-sm-4 col-form-label">{{ $t('labels.victim-support-centre') }}</label>
									<div class="col-sm-8 pt-2">
										{{ policeImportTicket.local_office.office.label }}
									</div>
								</div>
								<div class="form-group row" v-if="policeImportTicket.local_office">
									<label class="col-sm-4 col-form-label">{{ $t('labels.local-office') }}</label>
									<div class="col-sm-5 pt-2">
										{{ policeImportTicket.local_office.label }}
									</div>
								</div>

								<div class="form-group row">
									<label class="col-sm-4 col-form-label"></label>
									<div class="col-sm-5">
										<button v-if="isEditable" @click="toggleSelectLocalOffice" class="btn btn-success">{{ $t('labels.change-local-office') }}</button>
									</div>
								</div>
							</div>
							<div class="col-12 col-md-4 br-1">
								<h5 class="bb-1 pb-3 mb-3" style="font-size: 1rem">{{ $t('labels.geography') }}</h5>

								<select-field
									v-if="policeImportTicket.local_office"
									api="updatePoliceImportTicketField"
									:clearable="false"
									:model="policeImportTicket"
									:multiple="false"
									:options="policeImportTicket.local_office.municipality"
									db_field="municipality_id"
									field="municipality"
									:label="this.$t('labels.municipality')"
									:optionLabel="this.$label()"
									@fieldSaved="getPoliceImportTicket()"
									:editable="isEditable"
								></select-field>
							</div>
							<div class="col-12 col-md-4 br-1">
								<h5 class="bb-1 pb-3 mb-3" style="font-size: 1rem">{{ $t('labels.locally') }}</h5>

								<select-field
									api="updatePoliceImportTicketField"
									:model="policeImportTicket"
									:options="postal"
									db_field="postal_code_id"
									field="postal_code"
									:label="this.$t('labels.postal-code')"
									:clearable="false"
									optionLabel="postal_code"
									@fieldSaved="getPoliceImportTicket()"
									:editable="isEditable"
								>	<template v-slot:icon>
										<i v-b-toggle:postal_code_id
											><span class="fas fa-minus when-open"></span><span class="fas fa-plus when-closed"></span
										></i> </template
								></select-field>
								<b-collapse id="postal_code_id" class="form-group"><label>Polisens fil: </label> <mark>{{ policeImportTicket.postal_code_raw  }}</mark></b-collapse>

								<select-field
									api="updatePoliceImportTicketField"
									:clearable="false"
									:model="policeImportTicket"
									:multiple="false"
									:options="postalOptions()"
									db_field="postal_code_id"
									field="postal_code"
									:label="this.$t('labels.postal-area')"
									optionLabel="post_town"
									:editable="isEditable"
								>
									<template v-slot:icon>
										<i v-b-toggle:postal_area
											><span class="fas fa-minus when-open"></span><span class="fas fa-plus when-closed"></span
										></i> </template
								></select-field>
								<b-collapse id="postal_area" class="form-group"><label>Polisens fil: </label> <mark>{{ policeImportTicket.post_town_raw }}</mark></b-collapse>

								<!-- 	<div class="form-group row" v-if="postal.length == 0">
									<label class="col-4">{{ $t('labels.postal-code') }}</label>
									<div class="col-sm-8 text-muted">
										{{ $t('labels.not-available') }}
									</div>
								</div> -->

								<!-- 	<select-field
									v-if="ticket.county && postalUniqueNames.length !== 0"
									api="updateTicketField"
									:model="ticket"
									:options="postalUniqueNames"
									db_field="postal_code_id"
									field="postal_code"
									:label="this.$t('labels.postal-area')"
									:clearable="true"
									optionLabel="post_town"
									:editable="ticket.is_anonymized !== 1 && $store.getters.permissions.includes('ticket-section-geography-edit')"
								></select-field>

								<div class="form-group row" v-if="postalUniqueNames.length == 0">
									<label class="col-4">{{ $t('labels.postal-area') }}</label>
									<div class="col-sm-8 text-muted">
										{{ $t('labels.not-available') }}
									</div>
								</div> -->
							</div>
						</div>
					</div>
				</div>
				<div class="widget-body bt-1-dark pr-0 pl-3" v-if="showDetailSection === 4">
					<div class="container-fluid">
						<div class="row">
							<div class="col-12 br-1">
								<select-field
									api="updatePoliceImportTicketField"
									:clearable="false"
									:model="policeImportTicket"
									:multiple="false"
									:options="ticketFieldOptions.crime"
									db_field="crime_id"
									field="crime"
									:label="this.$t('labels.primary-crime-type-boj')"
									:optionLabel="this.$label()"
									:editable="isEditable"
									@fieldSaved="getPoliceImportTicket()"
								>
									<template v-slot:icon>
										<i v-b-toggle:crime_id
											><span class="fas fa-minus when-open"></span><span class="fas fa-plus when-closed"></span
										></i> </template
								></select-field>
								<b-collapse id="crime_id" class="form-group"><label>Polisens fil: </label> <mark>{{ policeImportTicket.police_crime_code_raw }}</mark></b-collapse>

								<text-field
									:model="policeImportTicket"
									api="updatePoliceImportTicketField"
									field="crime_scene_raw"
									:label="this.$t('labels.crime-scene')"
									@fieldSaved="getPoliceImportTicket()"
									:editable="isEditable"
								></text-field>
								<text-field
									:model="policeImportTicket"
									api="updatePoliceImportTicketField"
									field="crime_happened_at_raw"
									:label="this.$t('labels.crime-occurred')"
									@fieldSaved="getPoliceImportTicket()"
									:editable="isEditable"
								></text-field>
							</div>
							
									
						</div>
					</div>
				</div>
				<div class="widget-body bt-1-dark pr-0 pl-3" v-if="showDetailSection === 5">
					<div class="container-fluid">
						<div class="row">
							<div class="col-12 pb-5 " v-for="crime in policeImportTicket.police_import_secondary_crimes" :key="crime.id">
									<select-field
										api="updatePoliceImportCrimeField"
										:clearable="false"
										:model="crime"
										:multiple="false"
										:options="ticketFieldOptions.crime"
										db_field="crime_id"
										field="crime"
										:label="$t('labels.secondary-crime-type-boj')"
										:optionLabel="$label()"
										:editable="isEditable"
										@fieldSaved="getPoliceImportTicket()"
									>
										<template v-slot:icon>
											<i v-b-toggle="'secondary-' + crime.id"
												><span class="fas fa-minus when-open"></span><span class="fas fa-plus when-closed"></span
											></i> </template
									></select-field>
										<b-collapse :id="'secondary-' + crime.id" class="form-group"><label>Polisens fil: </label> <mark>{{ crime.crime_code_raw }}</mark></b-collapse>

									
									<text-field
										:model="crime"
										api="updatePoliceImportCrimeField"
										field="crime_scene_raw"
										:label="$t('labels.crime-scene')"
										@fieldSaved="getPoliceImportTicket()"
										:editable="isEditable"
									></text-field>
									<text-field
										:model="crime"
										api="updatePoliceImportCrimeField"
										field="crime_happened_at_raw"
										:label="$t('labels.crime-occurred')"
										@fieldSaved="getPoliceImportTicket()"
										:editable="isEditable"
									></text-field>
							</div>
						</div>
					</div>
				</div>
				<div class="widget-body bt-1-dark pr-0 pl-3" v-if="showDetailSection === 6">
					<div class="container-fluid">
						<div class="row">
							<div class="col-12 col-md-6 br-1">
								<h5 class="bb-1 pb-3 mb-3" style="font-size: 1rem;">{{ $t('labels.information') }}</h5>
								<text-field
									:model="policeImportTicket"
									api="updatePoliceImportTicketField"
									field="police_case_number_raw"
									:label="this.$t('labels.k-number')"
									:editable="isEditable"
								></text-field>
								<div style="position:relative">
									<select-field
										:options="ticketFieldOptions.police_support_org"
										db_field="police_support_org"
										field="police_support_org"
										:label="this.$t('labels.support-org')"
										:optionLabel="this.$label()"
										api="updatePoliceImportTicketField"
										:model="policeImportTicket"
									></select-field>

									<div style="position:absolute;top:10px;left:33.3333333333%;margin-left:20px;background:#fff;width: 50%">
										{{ selectedSupportOrgLabel }}
									</div>
								</div>
								<text-field
									:model="policeImportTicket"
									field="police_support_org_raw"
									:label="this.$t('labels.support-org-org')"
									:editable="false"
								></text-field>
								<text-field
									:model="policeImportTicket"
									api="updatePoliceImportTicketField"
									field="police_support_org_text"
									:label="this.$t('labels.support-org-text')"
									:editable="isEditable"
								></text-field>
								<text-field
									:model="policeImportTicket"
									api="updatePoliceImportTicketField"
									field="police_unit_raw"
									:label="this.$t('labels.handling-unit')"
									:editable="isEditable"
								></text-field>
								<text-field
									:model="policeImportTicket"
									api="updatePoliceImportTicketField"
									field="police_unit_secondary_raw"
									:label="this.$t('labels.handling-unit-shorten')"
									:editable="isEditable"
								></text-field>
								<text-field
									:model="policeImportTicket"
									api="updatePoliceImportTicketField"
									field="police_reported_date_raw"
									:label="this.$t('labels.registration-date')"
									:editable="isEditable"
								></text-field>
							</div>
							<div class="col-12 col-md-6 br-1">
									<h5 class="bb-1 pb-3 mb-3" style="font-size: 1rem;">{{ $t('labels.police-comment') }}</h5>

									<text-field
										:model="policeImportTicket"
										api="updatePoliceImportTicketField"
										field="police_comments_raw"
										label=""
										:rows="13"
										>
									</text-field>
								</div>
						</div>
					</div>
				</div>
			</div>
			<div style="position: relative">
				<transition name="slide-right">
					<router-view
						v-if="!isLoading"
						class="child-view"
						:key="id"
						:policeImportTicket="policeImportTicket"
						@updatePoliceImportTicket="updatePoliceImportTicket"
					></router-view>
				</transition>
			</div>
		</div>
	</layout-main>
</template>

<script>
import BojAPI from '@/api/boj';
import LayoutMain from '@/views/Layouts/Main';
import SelectField from '@/components/ticket/fields/SelectField';
import TextField from '@/components/ticket/fields/TextField';
import Checkbox from '@/components/ticket/fields/Checkbox';
import InfoBox from '@/components/InfoBox';
import SelectLocalOffice from '@/components/ticket/SelectLocalOfficePostalCode';
import TextareaField from '@/components/ticket/fields/TextareaField';

export default {
	name: 'localoffice-page',
	components: {
		SelectLocalOffice,
		LayoutMain,
		SelectField,
		TextField,
		Checkbox,
		InfoBox,
		TextareaField,
	},
	props: {
		id: Number,
	},
	data() {
		return {
			selectLocalOffice: false,
			ticketFieldOptions: [],
			showDetailSection: 1,
			isLoading: true,
			policeImportTicket: {
				errors: []
			},
		};
	},

	beforeRouteEnter(to, from, next) {
		BojAPI.getPoliceImportTicket(to.params.id)
			.then((response) => {
				next((vm) => vm.setData(response.data));
			})
			.catch((error) => {});
	},
	beforeRouteUpdate(to, from, next) {
		BojAPI.getPoliceImportTicket(to.params.id).then((response) => {
			this.setData(response.data);
			next();
		});
	},
	computed: {
		selectedSupportOrgLabel() {
			const options = this.ticketFieldOptions.police_support_org;
			if (!options) return null;

			const optionArray = typeof options === 'function' ? options() : options;
			const selectedOption = optionArray.find(
				(option) => option.id == this.policeImportTicket.police_support_org
			);

			return selectedOption ? selectedOption.label : null;
		},
		errorMessages() {
			let errors = this.policeImportTicket.errors;
			let messages = [];
			errors.forEach((element) => {
				messages.push(this.$to(element, 'message'));
			});

			return messages.join(', ');
		},
		postal() {
			return this.$store.getters.getPostalByMunicipality(this.policeImportTicket.municipality_id);
		},
		isEditable() {
			return !(this.policeImportTicket.is_imported == 1 || this.policeImportTicket.is_rejected == 1)
		}
	},
	mounted() {
		this.getTicketOptions();
	},
	methods: {
		isDisabled() {
			return !!this.policeImportTicket.is_rejected || !!this.policeImportTicket.is_imported || !!this.errorMessages.length > 0;
		},
		postalUniqueNames() {
			const postalData = this.$store.getters.getPostalByMunicipality(this.policeImportTicket.municipality_id);
			let names = postalData.filter((obj, pos, arr) => {
				return arr.map((mapObj) => mapObj['post_town']).indexOf(obj['post_town']) === pos;
			});

			return names.sort((a, b) => a.post_town.localeCompare(b.post_town));
		},
		postalOptions() {
			return this.policeImportTicket.municipality_id ? this.postalUniqueNames() : this.postalsByPostalCode();
		},
		postalsByPostalCode() {
			const postalData = this.$store.getters.getPostalByPostalCode(this.policeImportTicket.postal_code_raw);

			return postalData;
		},
		createTicket() {
			BojAPI.createTicketsFromPoliceTickets([this.policeImportTicket.id])
				.then((response) => {
					if (response.data.error_count) {
						this.$notify({
							group: 'foo',
							text: 'Polisärendet kunde inte importeras. Innehåller fel.',
							type: 'warning',
							duration: 3000,
						});
					} else {
						this.$notify({
							group: 'foo',
							text: 'Polisärendet har importerats.',
							type: 'success',
							duration: 3000,
						});
						//this.policeImportTicket.is_imported = 1;
						this.getPoliceImportTicket();
					}
				})
				.catch((error) => {
					this.showError(this.$t('labels.unknown-error'));
				});
		},
		deletePoliceImportTicket() {
			if (confirm('Är du säker?')) {
				BojAPI.deletePoliceImportTicket([this.policeImportTicket.id])
					.then((response) => {
						let self = this;
						this.$notify({
							group: 'foo',
							text: this.$t('labels.ticket-deleted'),
							type: 'success',
							duration: 3000,
						});
						setTimeout(function () {
							self.$router.push({ name: 'police-import' });
						}, 3000);
					})
					.catch((error) => {
						this.$notify({
							group: 'foo',
							text: this.$t('labels.no-permission-deleting-case'),
							type: 'warning',
							duration: 3000,
						});
					});
			}
		},
/* 		toggleApproved() {
			BojAPI.updatePoliceImportTicketField(this.policeImportTicket, 'is_approved', !this.policeImportTicket.is_approved).then((response) => {
				this.policeImportTicket.is_approved = !this.policeImportTicket.is_approved;
				if (this.policeImportTicket.is_approved) {
					this.$notify({
						group: 'foo',
						text: 'Polisärendet har godkänts',
						type: 'success',
						duration: 3000,
					});
				} else {
					this.$notify({
						group: 'foo',
						text: 'Polisärendet sattes till inte godkänd',
						type: 'success',
						duration: 3000,
					});
				}
			});
		}, */
		toggleRejected() {
			BojAPI.updatePoliceImportTicketField(this.policeImportTicket, 'is_rejected', !this.policeImportTicket.is_rejected).then((response) => {
				this.policeImportTicket.is_rejected = !this.policeImportTicket.is_rejected;
				if (this.policeImportTicket.is_rejected) {
					this.$notify({
						group: 'foo',
						text: 'Polisärendet har förkastats',
						type: 'success',
						duration: 3000,
					});
				} else {
					this.$notify({
						group: 'foo',
						text: 'Polisärendet sattes till godkänd',
						type: 'success',
						duration: 3000,
					});
				}
			});
		},
		getTicketOptions() {
			BojAPI.getTicketOptions().then((response) => {
				this.ticketFieldOptions = response.data;
			});
		},
		getPoliceImportTicket() {
			BojAPI.getPoliceImportTicket(this.policeImportTicket.id).then((response) => {
				this.setData(response.data);
			});
		},
		setShowDetailSection(section) {
			this.showDetailSection = section;
		},
		updatePoliceImportTicket(policeImportTicket) {
			this.setData({ data: policeImportTicket });
		},

		setData(payload) {
			this.policeImportTicket = payload;

			this.isLoading = false;
		},
		closeSelectOffice(local_office_id, local_office_label) {
			if (local_office_id) {
				BojAPI.updatePoliceImportTicketField(this.policeImportTicket, 'local_office_id', local_office_id).then((response) => {
				
					//this.policeImportTicket.local_office_id = local_office_id;
					//this.policeImportTicket.local_office = this.ticketFieldOptions.local_office.find((e) => e.id === local_office_id);
					this.getPoliceImportTicket();
				});
			/* 	const pos = this.policeImportTicket.errors.findIndex((el) => el.field === 'local_office_id');
				if (pos >= 0) {
					await BojAPI.deletePoliceImportError(this.policeImportTicket.errors[pos]).then((response) => {
						console.log('deleted error');
						this.policeImportTicket.errors.splice(pos, 1);
					});
				}
			 */
			
			
			/* let error = this.policeImportTicket.errors.find((e) => e.field === 'local_office_id');
				if(error) {
					await BojAPI.deletePoliceImportError(error).then((response) => {
						console.log('deleted error');
					});
				} */
			}
			this.selectLocalOffice = false;
		},
		toggleSelectLocalOffice() {
			this.selectLocalOffice = !this.selectLocalOffice;
		},
		updatePostalTown() {
			this.policeImportTicket.postal_code = this.policeImportTicket.postal_code_raw;
		},
	},
};
</script>
<style scoped>
.collapsed > .when-open,
.not-collapsed > .when-closed {
	display: none;
}
</style>
