<template>
    <modal name="send-message-modal" @closed="closeSendMail" :scrollable="true" :width="800" :height="670"
           :maxHeight="600">
      <div class="clearfix">
        <h4 class="float-left">{{ $t('labels.send-email') }}<info-box :field="'Medlemslistan  - Skicka e-postruta popup, rubrik'" /></h4>
        <button type="submit" class="btn btn-success float-right mt-3 mr-3" @click="sendMail">{{ $t('labels.send') }}</button>
        <button type="submit" class="btn btn-outline-danger float-right mt-3 mr-3" @click="closeSendMail">{{ $t('labels.cancel') }}</button>
      </div>
      <div class="container bt-1 pt-3">
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label for="send-mail-basic-to_email">Emails</label>
              <input type="text" class="form-control" id="send-mail-basic-to_email" aria-describedby="emailHelp"
                     placeholder="(Seperated by commas)"/>
            </div>
          </div>

          <div class="col-6">
            <div class="form-group">
              <label for="send-mail-basic-subject">{{ $t('labels.subject') }}</label>
              <input type="text" class="form-control" id="send-mail-basic-subject" aria-describedby="emailHelp"
                     placeholder=""/>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label for="subject">{{ $t('labels.signature') }}</label>
  
              <select v-model="signature" class="form-control">
                <option v-for="signature in signatures" :value="signature.id" :key="signature.id">{{
                    signature.name
                  }}
                </option>
              </select>
            </div>
          </div>
        </div>
  
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label>{{ $t('labels.body-text') }}</label>
              <textarea style="width:100%;min-height: 300px" id="send-mail-basic-body"></textarea>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </template>
  <script>
  import BojAPI from '@/api/boj';
  import 'quill/dist/quill.core.css';
  import 'quill/dist/quill.snow.css';
  import InfoBox from '@/components/InfoBox';
  
  export default {
    data() {
      return {
        currentBody: '',
        signatures: [],
        signature: null,
        body: '',
        subject: '',
        getSortByOrder: true,
        getSortBy: '_score',
      };
    },
    created() {
      this.currentBody = this.body;
    },
    mounted() {
      
      this.$modal.show('send-message-modal');
  
      BojAPI.getEmailSignatures().then((response) => {
        this.signatures = response.data;
      });
    },
    components: {
      InfoBox
    },
    methods: {
      closeSendMail() {
        this.$emit('closed');
      },
      sendMail() {
        this.$modal.hide('send-message-modal');
        this.$emit('closed');
        this.$emit('sendMail', {to_email: document.getElementById('send-mail-basic-to_email').value, subject: document.getElementById('send-mail-basic-subject').value, body: document.getElementById('send-mail-basic-body').value, signature: this.signature});
      },
    },
  };
  </script>
  